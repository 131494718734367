import styled from "@emotion/styled"
import { theme } from "./theme"

export const Section = styled.div`
         width: 97%;
         max-width: 98%;
         padding: ${theme.pm.pm200} ${theme.pm.pm200};
         margin: 0 auto;
         background: ${props => props.backgroundColor === "main" ? props.theme.colors.bgMain : (props.backgroundColor === "secondary" ? props.theme.colors.bgSecondary : "") };
       `
