export const theme = {
         colors: {
           primary: "#363945",
           white: "#fff",
           bgMain: "#F0F2F3",
           bgSecondary: "#F8FAFB",
           grey100: "#F5F8F9",
           grey200: "#737373",
           grey300: "#5E5E5E",
           grey400: "#454545",
           grey500: "#333333",
           grey900: "#001828",
         },
         pm: {
           pm100: "15px",
           pm200: "30px",
           pm300: "60px",
           pm400: "150px",
           pm500: "250px",
         },
         sizes: {
           navbarHeight: "75px",
           layoutWidth: "1400px",
           innerWidth: "800px",
         },
         text: {
           text800: "64px",
           text700: "56px",
           text600: "40px",
           text500: "34px",
           text400: "24px",
           text300: "20px",
           text200: "16px",
           text100: "14px",
         },
         breakpoints: {
           mobile: "480px",
           tablet: "768px",
         },
         boxShadows: {
           navbar: "0 8px 10px rgba(0, 0, 0, 0.1)",
           images: "2px 6px 8px rgba(0, 0, 0, 0.12)",
         },
       }
