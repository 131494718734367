import styled from "@emotion/styled"

export const HeadingPrimary = styled.h1`
  color: ${props => props.theme.colors.grey900};
  margin-bottom: 1rem;
  max-width: 100%;

  position: relative;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.mobile}) {
  }
`

export const HeadingSecondary = styled.h2`
  color: ${props =>
    props.color === "onDark" ? "#fff" : props.theme.colors.grey900};
  margin-bottom: 1rem;

  span {
    color: ${props => props.theme.colors.primary};
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.mobile}) {
  }
`

export const HeadingTertiary = styled.h3`
  color: ${props => props.theme.colors.grey900};
  margin-bottom: 1rem;

  span {
    color: ${props => props.theme.colors.primary};
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.mobile}) {
  }
`
